import { BrowserTracing, handleErrorWithSentry, init, setTag } from '@sentry/sveltekit';

import { PUBLIC_ENV } from '$env/static/public';
import { SENTRY_DSN } from '$lib/constants';

const rate: number = PUBLIC_ENV === 'production' ? 0.005 : 1;

init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: rate,
  sampleRate: rate,
  denyUrls: [/cdn\.moengage\.com/i],
  integrations: [new BrowserTracing()],
  environment: PUBLIC_ENV,
  enabled: true,
  ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured']
});

setTag('oneweather-web', 'client');
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
